import { IL10N } from '../../Common/l10n-keys';
import {
    ISalesDesignationView,
    ICurrencyInformation,
    ITelemetryData
} from '../articleTileGrid/article-tile/article-tile.d';
import { IKioskInfo } from '../categorypage/categorypage.d';
import { IFasBarData } from '../fas-bar/fas-bar.d';
import { GlobalState } from '../globalState/globalState';
import { ArticleTilesAjaxCaller } from '../articleTileGrid/ArticleTilesAjaxCaller';

export interface ISearchResultPageProps {
    l10n: IL10N;
    searchResultPageProps: ISearchResultPage;
}

export interface ISearchResultPageState {
    salesDesignationViews: ISalesDesignationView[];
    pageNo: number;
    totalResultCount: number;
    pageEndOffset: number;
    totalPageCount: number;
    waitingForResponse: boolean;
    isBreadcrumbOnly: boolean;
    componentMounted: boolean;
    scrollPosition: number;
    activeDropDownFilterName: string;
    stickyHeaderDropDownFilterName: string;
    isStickyHeaderVisible: boolean;
    toggleToUpdateFasBar: boolean;
    isTeaserViewResultPage: boolean;
    isEmptySearchResultPage: boolean;
    infiniteScrollUpMode: boolean;
    infiniteScrollUpMinPageNo: number;
    isSearchBottomReached: boolean;
}

export interface ISearchResultPage {
    articleLists: IArticleList[];
    bottomPageSearchBoxFragment: IFragmentPlaceholder;
    bottomSuggestionLinks: ISuggestionLinks;
    currencyInformation?: ICurrencyInformation;
    filterContentViewModel: IFasBarData;
    languageSwitcherFragment: ILanguageSwitcherFragmentProps;
    originalRootPath: string;
    originalSearchTerm: string;
    pagingInformation: IPagingInformation;
    style: string;
    topPageSearchBoxFragment: ISearchInsteadBoxProps;
    topSuggestionLinks: ISuggestionLinks;
    type: SearchResultPageType;
    url: string;
}

export interface IArticleList {
    alternativeTarget: string;
    articles: ISalesDesignationView[];
    filterAndSortBar: IFilterAndSortForSearch;
    isAlternative: boolean;
    paging: IPagingInformation;
    searchString: string;
    style: string;
    telemetryDataViewModel: ITelemetryData;
    totalResultCount: number;
    types: string[];
}

export interface ITeaserSuggestionsProps {
    articleLists: IArticleList[];
    currencyInformation?: ICurrencyInformation;
    kiosk: IKioskInfo;
    l10n: IL10N;
    isMobile: boolean;
    portal: string;
    culture: string;
    isGlobal: boolean;
    globalState: GlobalState;
    articleTilesAjaxCaller: ArticleTilesAjaxCaller;
}

export interface ITeaserSuggestionsState {
    salesDesignationViewList: ISalesDesignationView[][];
}

export interface ISearchInsteadBoxProps {
    htmlEncodedAppropriateResultNotFoundText: string;
    htmlEncodedSearchInsteadText: string;
    originalSearchTerm: string;
    soldOutArticles: ISoldOutArticle[];
    style: string;
    topPageSearchBoxFragment: IFragmentPlaceholder;
    l10n: IL10N;
}

export interface ISearchInsteadBoxState {
    showTopSearchbox: boolean;
    showSingleAltHeadline: boolean;
}

export interface ISuggestionLinks {
    labelLocalizationKey: string;
    style: string;
    suggestionLinks: ISuggestionLink[];
}

export interface ISuggestionLinksState {
    isScrollable: boolean;
    showLeftChevron: boolean;
    showRightChevron: boolean;
}

export interface ISuggestionLink {
    searchTerm: string;
    portal: string;
    culture: string;
    target: string;
    telemetryDataViewModel: ITelemetryData;
}

export interface ILanguageSwitcherFragmentProps {
    culture: string;
    portal: string;
    fragment: IFragmentPlaceholder;
    telemetryDataViewModel: ITelemetryData;
    style: string;
}

export interface ISoldOutArticle {
    articleName: string;
    salesArticleNo: string;
}

export interface IFilterAndSortForSearch {
    breadcrumbViewModel: IFragmentPlaceholder;
    filterAndSortViewModel: IFragmentPlaceholder;
    mainSidePanelViewModel: IFragmentPlaceholder;
}

export interface IFragmentPlaceholder {
    dClass: string;
    fallbackSource: string;
    features: string;
    ignoreErrors?: boolean;
    source: string;
    timeout?: number;
}

export interface IPagingInformation {
    pageNumber: number;
    totalPageCount: number;
    totalResultCount: number;
    pageEndOffset: number;
}

export enum SearchResultPageType {
    Empty,
    Regular,
    FullAlternativeView,
    TeaserAlternativeView,
}

export enum OriginType {
    LanguageSwitcher,
    RelatedTerm,
    TypoCorrection,
    UmbrellaTerm,
}

export enum ContentTypes {
    ArticleTile,
    LanguageSwitcher,
    ShowMoreArticles,
    SuggestionPillBottom,
    SuggestionPillTop,
}

export function ArticleListsToSdvListMapper(
    lists: IArticleList[]
): ISalesDesignationView[][] {
    return lists.map(x=>x.articles);
}

export function SearchResultPagePropsToSdvMapper(
    props: ISearchResultPageProps
): ISalesDesignationView[] {
    const salesDesignationViews: ISalesDesignationView[] = [];

    props.searchResultPageProps.articleLists.map((list) => {
        list.articles.map((article) => salesDesignationViews.push(article))
    });

    return salesDesignationViews;
}

export interface IMetaEventForSearchResult {
    search_string: string;
    content_ids: string[];
}